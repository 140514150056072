<template>
  <div class="findPass animated bounceInRight">
    <back style="color:#000" />
    <h2 v-if="isWishCompany" class="welcome">爱心企业找回密码</h2>
    <h2 v-else class="welcome">会员找回密码</h2>
    <div class="usermsg">
      <div class="msgList">
        <span class="iconfont icon-dianhua-"></span>
        <van-field v-model="phone" type="tel" placeholder="请输入手机号" clearable />
      </div>

      <div class="msgList">
        <span class="icon-baohu iconfont"></span>
        <van-field v-model="code" clearable placeholder="请输入验证码" />
        <span class="red" @click="getCode" v-if="codeFlag">获取验证码</span>
        <span class="red" style="min-width: 130px;" v-else>({{currentS}})秒后重新获取</span>
      </div>

      <div class="msgList">
        <span class="icon-mima iconfont"></span>
        <van-field v-model="pass" clearable type="password" placeholder="6-16位密码(数字和字母的组合)" />
      </div>
    </div>

    <div class="edit_btn">
      <van-button type="danger" round class="register_btn" size="small" @click="findPass">确认</van-button>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import authApi from "@/api/auth";
export default {
  data() {
    return {
      phone: "",
      code: "",
      pass: "",
      currentS: 60,
      codeFlag: true,
      appName: this.appName,
      isWishCompany: false,  // 判断是普通会员还是爱心企业
      forgetStr: 'app/ap_member//member/query/forgot/password',  // 忘记密码接口
      codeStr: 'app/ap_member//member/query/check/phone',  // 获取验证码接口
    };
  },
  created() {
    // 有路由后缀代表是爱心企业，并更换接口
    if (this.$route.query.wishSign) {
      this.isWishCompany = true;
      this.forgetStr = 'app/ap_member//wish/member/wishMemberForgetPassword';
      this.codeStr = 'app/ap_member//wish/member/checkWishMemberIsTrue';
    } else {
      this.isWishCompany = false;
      this.forgetStr = 'app/ap_member//member/query/forgot/password';
      this.codeStr = 'app/ap_member//member/query/check/phone';
    }
  },
  methods: {
    sendcode() {
      this.codeFlag = !this.codeFlag;
      this.currentS = 60;
      var timeId = setInterval(() => {
        this.currentS--;
        if (this.currentS == 0) {
          this.codeFlag = !this.codeFlag;
          clearInterval(timeId)
          return;
        }
      }, 1000);

      var params = {
        phone: this.phone,
        appName: this.appName
      }
      authApi.obtainCode(params).then(res => {
        if (res.code === 3007) {
          Toast("获取验证码成功")
        } else {
          Toast(res.message)
        }
      }).catch(err => {
        Toast("请检查网络是否可用!")
      });
    },
    //获取验证码
    getCode() {
      if (!this.phone) {
        Toast("请输入手机号");
        return;
      }
      if (this.phone.length != 11) {
        Toast("手机号输入有误");
        return;
      }

      var params = {
        appName: this.appName,
        phone: this.phone
      }
      if (this.$route.query.wishSign) { // 企业获取手机号
        authApi.checkWishMemberIsTrue(params).then(res => {
          if (res.data == false) {
            Toast("该手机号还未注册")
            return
          } else {
            this.sendcode();
          }
        })
      } else { // 会员获取手机号
        authApi.checkRegisterPhone(params).then(res => {
          if (res.data == false) {
            Toast("该手机号还未注册");
            return
          } else {
            this.sendcode();
          }
        })
      }
    },
    findPass() {
      if (!this.phone) {
        Toast("请输入手机号")
        return
      }
      if (this.phone) {
        if (this.phone.length !== 11) {
          Toast("请输入1开头的11位合法手机号")
          return
        }
      }
      if (!this.pass) {
        Toast("请输入密码")
        return
      }
      if (!this.code) {
        Toast("请输入验证码")
        return
      }
      if (this.pass) {
        if (this.pass.length > 16 || this.pass.length < 6) {
          Toast("请输入6-16位密码(数字和字母的组合)");
          return
        }
      } else {
        Toast("请输入密码")
      }

      var params = {
        code: this.code,
        phone: this.phone
      }
      authApi.verificationCodeIsTrue(params).then(res => {
        if (res.code === 10000) {
          // 验证成功
          this.uptate();
        } else {
          Toast("验证码错误")
        }
      })
    },
    // 这里根据用户类型换接口
    uptate() {
      var params = {
        newPassword: this.pass,
        appName: this.appName,
        phone: this.phone
      }
      if (this.$route.query.wishSign) { // 企业
        authApi.wishMemberForgetPassword(params).then(res => {
          if (res.code === 10000) {
            Toast("修改成功");
            window.localStorage.userMsg = JSON.stringify({
              username: this.phone,
              password: this.pass
            });
            this.$router.go(-1);
          } else {
            Toast(res.message);
          }
        }).catch(err => {
          console.log(err)
          Toast("网络错误，请刷新后重试")
        })
      } else { // 会员
        authApi.MemberForgetPassword(params).then(res => {
          if (res.code === 10000) {
            Toast("修改成功");
            window.localStorage.userMsg = JSON.stringify({
              username: this.phone,
              password: this.pass
            });
            this.$router.go(-1);
          } else {
            Toast(res.message);
          }
        }).catch(err => {
          console.log(err)
          Toast("网络错误，请刷新后重试")
        })
      }
    }
  }
};
</script>

<style lang="less" scoped>
.findPass {
  padding: 0 20px;
  box-sizing: border-box;
  .back {
    font-size: 20px;
    position: absolute;
    left: 10px;
    top: 40px;
  }
  .red {
    color: #e42417;
    float: right;
    font-size: 14px;
    min-width: 100px;
    text-align: center;
  }
  .edit_btn {
    margin: 30px auto 0;
    display: flex;
    justify-content: center;
    .register_btn {
      padding: 0 30px;
      font-size: 16px;
    }
  }
  .usermsg {
    margin-top: 45px;
    padding: 0 15px;
    box-sizing: border-box;
    .msgList {
      display: flex;
      justify-content: left;
      align-items: center;
      border-bottom: 1px solid #eee;
    }
  }
  .welcome {
    font-size: 20px;
    font-weight: 700;
    margin-top: 80px;
  }
}
</style>